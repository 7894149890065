import React from 'react';
import ReactDOM from 'react-dom';
import {Provider, useDispatch} from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import {AnyAction, configureStore, Dispatch, ThunkDispatch} from '@reduxjs/toolkit'
import 'semantic-ui-css/semantic.min.css';

const MAIN_APP = 'App';
const APP = process.env.REACT_APP_DIR || MAIN_APP;

const store = configureStore({
    reducer: {
    },
    // middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger),
    devTools: process.env.NODE_ENV !== 'production'
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, null, AnyAction> & Dispatch; //typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

import(`./components/${APP}/App`).then(({default: App}) => {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
